<template>
  <div class="main">
    <div class="wrap">
      <ListStandardDefault
        ref="list"
        :content="content"
        class="items"
        :class="{ loading: content.loading }"
        :active-item-id="activeItemIdFromMap"
        @item-mouseover="handleItemMouseOver"
        @item-mouseout="handleItemMouseOut"
      />

      <ListFooter v-if="showFooter" :class="{ loading: content.loading }" />

      <TeaserListSkeleton
        v-if="content.loading"
        :length="content.items.length"
        class="skeleton"
      />
    </div>

    <ListMap
      v-if="showMap"
      :content="content"
      :external-active-item-id="activeItemId"
      @marker-click="handleMarkerClick"
    />
  </div>
</template>

<script lang="ts" setup>
import type { List } from '../models';

defineProps<{
  content: List;
  showMap: boolean;
  showFooter: boolean;
}>();

const activeItemId = ref<number | null>(null);
const activeItemIdFromMap = ref<number | null>(null);

const handleItemMouseOver = (itemId: number): void => {
  activeItemId.value = itemId;
};
const handleItemMouseOut = (): void => {
  activeItemId.value = null;
};

const handleMarkerClick = (itemId: number): void => {
  activeItemIdFromMap.value = itemId;
};
</script>

<style src="./Main.scss" scoped lang="scss"></style>
