<template>
  <section
    class="list global-grid"
    :class="[{ hasMap: content.showMap }, { showMap: showMap }]"
  >
    <div class="wrap">
      <ListStandardTitle
        v-if="content.showTitle"
        :title="content.title"
        :list-type="listType"
        :allowed-list-types="allowedListTypes"
        :hide-switch-small="content.showMap"
        @switch="switchType"
      />

      <ListStandardMain
        v-if="listType === 'default'"
        :content="content"
        :show-map="content.showMap && showMap"
        :show-footer="totalRecords > globalStore.state.pageSize"
      />

      <ListStandardTile
        v-if="listType === 'tile'"
        :content="content"
        :show-footer="totalRecords > globalStore.state.pageSize"
        class="items"
      />

      <ListMapSwitch
        :show-map="content.showMap"
        :model-value="showMap"
        :count="2"
        @switch="switchMap"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { List } from './models';

defineProps<{
  content: List;
  allowedListTypes: string[];
  totalRecords: number;
}>();

const globalStore = useGlobalStore();

const listType = computed(() =>
  globalStore.state.listType === 'gallery' ? 'tile' : 'default'
);

const showMap = ref<boolean>(false);
const redrawMapBus = useEventBus<never>('redrawMap');

const switchMap = async () => {
  showMap.value = !showMap.value;

  if (showMap.value) {
    globalStore.state.listType = 'list';

    await nextTick();
    redrawMapBus.emit();
  }
};

const switchType = () => {
  useGlobalStore().toggleListType();

  if (globalStore.state.listType !== 'list') {
    showMap.value = false;
  }
};
</script>

<style src="./List.scss" scoped lang="scss"></style>
