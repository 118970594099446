<template>
  <div class="map">
    <MtkMap
      ref="mtkMap"
      :center="mapCenter"
      :bounds="bounds"
      @map-init="ensureMapBounds"
    >
      <MtkMapObjectMarker
        v-for="item in content.items"
        :key="item.id"
        :coords="item.coords"
        @marker-click="displayInfoWindowForItem(item.id)"
      />
    </MtkMap>

    <TeaserList v-if="activeItem" :content="activeItem" />
  </div>
</template>

<script lang="ts" setup>
import type { BaseCoords } from '../../../../models/BaseCoords';
import { convertBaseCoordsToLatLngLike } from '../../../../utils/convertBaseCoords';
import type { List } from '../../Standard/models';

const props = defineProps<{
  content: List;
  externalActiveItemId: number | null;
}>();

const emit = defineEmits(['marker-click']);

const mapCenter = ref<BaseCoords | undefined>(undefined);
const bounds = ref<{ lat: number; lon: number }[] | undefined>(undefined);

const ensureMapBounds = (): void => {
  if (props.content?.items?.length > 0) {
    const ne: Nullable<BaseCoords> = { latitude: null, longitude: null };
    const sw: Nullable<BaseCoords> = { latitude: null, longitude: null };

    props.content.items.forEach((item) => {
      if (item.coords) {
        if (!ne.latitude || item.coords.latitude > ne.latitude) {
          ne.latitude = item.coords.latitude;
        }
        if (!ne.longitude || item.coords.longitude > ne.longitude) {
          ne.longitude = item.coords.longitude;
        }
        if (!sw.latitude || item.coords.latitude < sw.latitude) {
          sw.latitude = item.coords.latitude;
        }
        if (!sw.longitude || item.coords.longitude < sw.longitude) {
          sw.longitude = item.coords.longitude;
        }
      }
    });

    bounds.value = [
      convertBaseCoordsToLatLngLike(sw as BaseCoords),
      convertBaseCoordsToLatLngLike(ne as BaseCoords),
    ];
  }
};

const activeItemId = ref<number | null>(null);
watch(
  () => props.content?.items,
  () => {
    ensureMapBounds();
  },
  { immediate: true }
);

// watch for change from outside list (desktop)
watch(
  () => props.externalActiveItemId,
  (newValue: number | null) => {
    activeItemId.value = newValue;

    const item = props.content.items.find((item) => item.id === newValue);
    if (item?.coords) {
      mapCenter.value = item.coords;
    } else {
      mapCenter.value = undefined;
    }
  },
  { immediate: true }
);

const displayInfoWindowForItem = (itemId: number): void => {
  activeItemId.value = itemId;

  // emit event to parent component (which is active on desktop and handles scrolling of list)
  emit('marker-click', itemId);
};

const activeItem = computed(() => {
  return props.content.items.find((item) => item.id === activeItemId.value);
});
</script>

<!-- call this component MapWrap, because Map is not usable -->
<style src="./Map.scss" scoped lang="scss"></style>
